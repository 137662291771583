<template>
	<div class="relative w-full py-8">
		<slot
			v-if="!hideHeader"
			name="header"
		>
			<h2 class="px-4 pb-4 text-3xl font-bold text-center">
				{{ headerText }}
			</h2>
		</slot>
		<Transition name="fade">
			<slot
				name="previous"
				:has-multiple-slides="hasMultipleSlides"
				:show-previous-arrow="showPreviousArrow"
				:scroll-left="scrollLeft"
			>
				<div
					v-show="showPreviousArrow && hasMultipleSlides"
					:class="[
						'absolute z-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2',
						arrowVisibility,
						leftArrowPosition
					]"
				>
					<button
						class="transition-all duration-100 ease-in-out"
						:class="[
							arrowButtonClasses,
							arrowPreviousOffset
						]"
						@click="scrollLeft()"
					>
						<span
							:class="[
								arrowSizeClasses,
								arrowColor,
								'flex content-center justify-center text-4xl font-bold text-center align-middle'
							]"
						>
							<IconArrow
								:class="[
									arrowColor,
									'transform rotate-180 m-auto'
								]"
							/>
						</span>
					</button>
				</div>
			</slot>
		</Transition>
		<Transition name="fade">
			<slot
				name="next"
				:scroll-right="scrollRight"
				:show-next-arrow="showNextArrow"
				:has-multiple-slides="hasMultipleSlides"
			>
				<div
					v-show="showNextArrow && hasMultipleSlides"
					:class="[
						'absolute z-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2',
						arrowVisibility,
						rightArrowPosition
					]"
				>
					<button
						class="transition-all duration-100 ease-in-out"
						:class="[
							arrowButtonClasses,
							arrowNextOffset
						]"
						@click="scrollRight()"
					>
						<span
							:class="[
								arrowSizeClasses,
								arrowColor,
								'flex content-center justify-center text-4xl font-bold text-center align-middle'
							]"
						>
							<IconArrow
								:class="[
									arrowColor,
									'm-auto'
								]"
							/>
						</span>
					</button>
				</div>
			</slot>
		</Transition>
		<div
			ref="carousel"
			class="overflow-y-hidden"
			@mouseover="pauseAutoplay"
			@mouseout="resumeAutoplay"
		>
			<div class="relative grid grid-cols-1 gap-4">
				<div class="relative">
					<div
						ref="slider"
						:class="[
							'flex overflow-scroll overflow-y-hidden transition-all duration-500 ease-in-out snap-x snap-mandatory',
							sliderClasses,
							scrollbarVisibility
						]"
					>
						<slot name="slides" />
					</div>
				</div>
			</div>
		</div>
		<slot
			v-if="!hideFooter"
			name="footer"
			:set-slide="setSlide"
			:previous="previous"
			:next="next"
		>
			<div
				:class="[
					'absolute w-full space-x-3 transform -translate-x-1/2 xl:flex left-1/2 flex items-center justify-center',
					bulletVisibility,
					bulletPositionClasses
				]"
			>
				<div
					v-if="slideCount && !hideBulletNavigation"
					class="flex flex-wrap justify-center space-x-3"
				>
					<button
						v-for="(_, index) in slideCount"
						:key="index"
						:class="[
							'flex justify-center border-none rounded-full flex-nowrap transition-all duration-300 ease-in-out focus:rounded-full',
							{ 'ring-offset-2': !noBulletRingOffset },
							bulletOuterClasses
						]"
						title="Slide Control Button"
						@keyup.left="previous()"
						@keyup.right="next()"
						@click="setSlide(index)"
					>
						<span
							:class="[
								bulletSizeComputed,
								'relative rounded-full'
							]"
						>
							<span
								:class="[
									index === scrollIndex? `${bulletActiveColor}` : `${bulletInactiveColor}`,
									{ 'ring-offset-2': !noBulletRingOffset },
									bulletInnerClasses,
									'absolute top-0 left-0 z-10 w-full h-full border-none rounded-full transform transition-all duration-300 ease-in-out ring-transparent ring-2 ring-offset-transparent'
								]"
							/>
							<span
								:class="[
									index === scrollIndex ? `${bulletInactiveColor} scale-0` : `${bulletInactiveColor} scale-100`,
									{ 'ring-offset-2': !noBulletRingOffset },
									bulletInnerClasses,
									'absolute top-0 left-0 z-20 w-full h-full border-none rounded-full transform transition-all duration-300 ease-in-out ring-transparent ring-2 ring-offset-transparent'
								]"
							/>
						</span>
					</button>
				</div>
			</div>
		</slot>
	</div>
</template>

<script setup lang="ts">
import { ref, toRefs, nextTick, onUnmounted, onMounted, watchEffect, watch, computed } from 'vue'
import type { BulletSize, ScrollSnapAlign } from '@/components/carousel/types'
import type { CarouselNavigationEvent } from '@/types/gtm/events'
import { CAROUSEL_SLIDE_CHANGED } from '@/constants/gtm/events'
import { useTrackEvent } from '@/composables/useEventTracking'

const props = defineProps({
	id: {
		type: String,
		required: true
	},
	hideHeader: {
		type: Boolean,
		default: false
	},
	hideFooter: {
		type: Boolean,
		default: false
	},
	headerText: {
		type: String,
		default: 'Carousel'
	},
	sliderClasses: {
		type: String,
		default: 'space-x-4 divide-x-2 divide-gray-200 pb-4 pr-4'
	},
	gapOffset: { // the gap between the carousel items (e.g. space-x-4 = 16px)
		type: Number,
		default: 16
	},
	scrollSnapAlign: {
		type: String as PropType<ScrollSnapAlign>,
		default: 'snap-start'
	},
	scrollbarVisibility: {
		type: String,
		default: 'lg:hide-horizontal-scrollbar'
	},
	bulletVisibility: {
		type: String,
		default: ''
	},
	hideBulletNavigation: {
		type: Boolean,
		default: false
	},
	noBulletRingOffset: { // removes the ring offset from the bullet navigation (useful for when the bullet navigation is on a dark background)
		type: Boolean,
		default: false
	},
	bulletInactiveColor: {
		type: String,
		default: 'bg-gray-300'
	},
	bulletActiveColor: {
		type: String,
		default: 'bg-mx-orange'
	},
	bulletOuterClasses: {
		type: String,
		default: 'focus:border-gray-300 ring-transparent focus:mx-orange-muted hover:mx-orange-muted focus:ring-2'
	},
	bulletInnerClasses: {
		type: String,
		default: 'hover:ring-mx-orange-muted hover:ring-offset-white'
	},
	bulletSize: {
		type: String as PropType<BulletSize>,
		default: 'medium'
	},
	bulletPositionClasses: {
		type: String,
		default: 'bottom-4'
	},
	arrowVisibility: {
		type: String,
		default: ''
	},
	arrowSizeClasses: {
		type: String,
		default: 'w-10 h-10'
	},
	arrowButtonClasses: {
		type: String,
		default: 'border-0 border-none rounded-full ring-offset-2 hover:ring focus:ring ring-mx-orange-muted focus:border-mx-orange-muted bg-mx-orange'
	},
	arrowNextOffset: {
		type: String,
		default: ''
	},
	arrowPreviousOffset: {
		type: String,
		default: ''
	},
	rightArrowPosition: {
		type: String,
		default: '-right-10'
	},
	leftArrowPosition: {
		type: String,
		default: 'left-0'
	},
	arrowColor: {
		type: String,
		default: 'text-white'
	},
	autoplay: {
		type: Boolean,
		default: false
	},
	autoplayInterval: {
		type: Number,
		default: 5000
	},
	initialSlideIndex: {
		type: Number,
		default: 0
	},
	initialItemIndex: {
		type: Number,
		default: 0
	}
})

const emit = defineEmits([ 'scrollIndex' ])

// destructure props and make refs
const { gapOffset, bulletSize, autoplay, autoplayInterval, initialItemIndex, initialSlideIndex, scrollSnapAlign } = toRefs(props)
// timer id for autoplay
const timerId = ref<ReturnType<typeof setInterval> | undefined>(undefined)
// used to pause the carousel autoplay when the user is interacting with it
const paused = ref(false)
// outer container
const carousel = ref<HTMLElement | null>(null)
// inner container with overflow
const slider = ref<HTMLElement | null>(null)
// total number of slides
const slideCount = ref(0)
// the number of items that can fit in the carousel container without overflowing
const itemsPerSlide = ref(0)
// this index is used when using the buttons to navigate the carousel
const activeSlideIndex = ref(0)
// obtained from scroll event listener
const scrollPosition = ref(0)
// array containing widths of each carousel item
const carouselItemWidths = ref<number[]>([])
// get the carousel item elements
const carouselItemRefs = computed(() => {
	return slider.value ? Array.from(slider.value.children) as HTMLElement[] : []
})
// get the total number of carousel items
const itemCount = computed(() => {
	return carouselItemRefs.value?.length || 0
})
// array of scroll positions for each slide
const slideScrollPositions = computed(() => {
	const array = [ 0 ] // the first slide is always at scrollLeft position 0
	let scrollLeft = 0
	for (let i = 0; i < slideCount.value - 1; i++) {
		scrollLeft += (carouselItemWidths.value[i] + gapOffset.value) * (itemsPerSlide.value)
		array.push(scrollLeft)
	}
	return array
})
const scrollIndex = computed(() => { // use this index when manually scrolling the carousel to update
	// the active bullet button color to prevent handleScroll() from being called twice
	const currentPosition = scrollPosition.value
	// next, get the index of the active slide where the scroll position is within the range
	// between the scroll position of the current slide index and the next slide index
	const index = slideScrollPositions.value.findIndex((slideScrollPosition, i) => {
		return currentPosition >= slideScrollPosition && currentPosition < slideScrollPositions.value[i + 1]
	})
	// check if the scroll position is greater than the scroll position of the second to last slide + the gap offset
	const useLastSlide = currentPosition > slideScrollPositions.value[slideScrollPositions.value.length - 2] + gapOffset.value
	return useLastSlide ? slideScrollPositions.value.length - 1 : index
})
const showPreviousArrow = computed(() => {
	return activeSlideIndex.value > 0
})
const showNextArrow = computed(() => {
	return activeSlideIndex.value <= slideCount.value - 2
})
const hasMultipleSlides = computed(() => {
	return slideCount.value > 1
})
const bulletSizeComputed = computed(() => {
	switch (bulletSize.value) {
	case 'small':
		return 'w-3 h-3'
	case 'large':
		return 'w-6 h-6'
	default:
		return 'w-4 h-4'
	}
})
// slot method for previous arrow - go to previous slide or go to last slide if on first slide
const previous = () => {
	pauseAutoplay()
	activeSlideIndex.value = showPreviousArrow.value ? activeSlideIndex.value-- : slideCount.value - 1
	resumeAutoplay()
}
// slot method for next arrow - go to next slide or go to first slide if on last slide
const next = () => {
	pauseAutoplay()
	activeSlideIndex.value = showNextArrow.value ? activeSlideIndex.value++ : 0
	resumeAutoplay()
}
// arrow button scroll - go to next slide
const scrollRight = () => {
	pauseAutoplay()
	activeSlideIndex.value++
	resumeAutoplay()
}
// arrow button scroll - go to previous slide
const scrollLeft = () => {
	pauseAutoplay()
	activeSlideIndex.value--
	resumeAutoplay()
}
// autoplay - scroll to the active slide
const moveSlide = () => {
	if (!showNextArrow.value) {
		activeSlideIndex.value = 0
	} else {
		activeSlideIndex.value++
	}
}
const startAutoplay = () => {
	if (autoplay.value) {
		timerId.value = setInterval(moveSlide, autoplayInterval.value)
	}
}
const pauseAutoplay = () => {
	if (autoplay.value) {
		paused.value = true
		clearInterval(timerId.value)
	}
}
const resumeAutoplay = () => {
	if (autoplay.value) {
		paused.value = false
		startAutoplay()
	}
}
const setSlide = (index: number) => {
	activeSlideIndex.value = index

	handleScroll() // force scroll even if the currentIndex is the same (since the bullet color is a different index value,
	// simply scrolling will not update the activeIndex - which can cause the watcher to not fire if
	// you are trying to nav to the same index again)
}
const initializeCarousel = () => { // update the number of slides based on the width of the carousel container
	if (carousel.value && slider.value) {
		// get the width of each carousel item
		carouselItemWidths.value = Array.from(slider.value.children).map((item: Element) => (item as HTMLElement).offsetWidth)
		// get the number of items that can fit in the carousel container without overflowing
		itemsPerSlide.value = Math.floor(carousel.value.offsetWidth / carouselItemWidths.value[0])
		// check if the carousel item is larger than the container to prevent dividing by 0
		const carouselItemIsLargerThanContainer = itemsPerSlide.value === 0
		// check if there is only one slide and set the number of slides to 0 if so
		const isSingleSlide = itemsPerSlide.value >= itemCount.value || carouselItemIsLargerThanContainer
		// set the number of slides based on the number of items that can fit in the carousel container without overflowing
		slideCount.value = isSingleSlide ? 0 : Math.ceil(itemCount.value / itemsPerSlide.value)
		if (initialItemIndex.value) { // set navigation control index based on the slide that the initial item is on
			activeSlideIndex.value = Math.floor(initialItemIndex.value / itemsPerSlide.value)
		} else { // set navigation control index based on the initial slide prop
			activeSlideIndex.value = initialSlideIndex.value
		}
	}
}
const handleScroll = (index = activeSlideIndex.value) => {
	// set the scrollLeft value of the carousel by adding each slide width together
	// up to the current activeSlideIndex
	let scrollLeft = 0
	for (let i = 0; i < index; i++) {
		scrollLeft += (carouselItemWidths.value[i] + gapOffset.value) * (itemsPerSlide.value)
	}
	if (slider.value) {
		slider.value.scrollTo({ // scroll the slider smoothly to the new scrollLeft value
			left: scrollLeft,
			behavior: 'smooth'
		})
	}
}
watchEffect(() => { // watch for changes to the carousel container and
	// update the number of slides to display
	if (carousel.value) {
		initializeCarousel()
	}
})
watch(activeSlideIndex, () => { // watch for changes to the activeSlideIndex and
	// update the scroll position of the carousel
	handleScroll()
})
// watch carouselItemRefs for changes and add the class 'snap-start' to each carousel item if it doesn't already have it
watch(carouselItemRefs, () => {
	carouselItemRefs.value.forEach((item: HTMLElement) => {
		if (!item.classList.contains(scrollSnapAlign.value)) {
			item.classList.add(scrollSnapAlign.value)
		}
	})
})
watch(scrollIndex, () => {
	emit('scrollIndex', scrollIndex.value)
	const trackedEvent: CarouselNavigationEvent = {
		event: CAROUSEL_SLIDE_CHANGED,
		category: 'Carousel',
		action: 'navigation',
		label: 'Carousel Slide Changed',
		slide_index: scrollIndex.value,
		carousel_id: props.id
	}
	useTrackEvent(trackedEvent)
})
onMounted(() => {
	if (slider.value) {
		slider.value.addEventListener('scroll', () => { // watch for changes to the scroll position
		// of the carousel and update the scrollPosition
			if (slider.value?.scrollLeft) {
				scrollPosition.value = slider.value.scrollLeft
			}
		})
	}

	window.addEventListener('resize', () => { // on window resize, update the number of slides to display
		initializeCarousel()
	})
	nextTick(() => { // set interval to check if the carousel items have loaded
		const interval = setInterval(() => {
			if (carousel.value) {
				initializeCarousel()
				handleScroll() // scroll to initial slide
				clearInterval(interval)
			}
		}, 50)
	})
	startAutoplay()
})
onUnmounted(() => { // remove the event listeners
	window.removeEventListener('resize', () => {
		initializeCarousel()
	})
	if (slider.value) {
		slider.value.removeEventListener('scroll', () => {
			if (slider.value?.scrollLeft) {
				scrollPosition.value = slider.value.scrollLeft
			}
		})
	}
	clearInterval(timerId.value) // clear the interval for autoplay
})
</script>
